import React from 'react'
import BlogRow from '../BlogRow'
import BlogMedia from '../BlogMedia'
import BlogContent from '../BlogContent'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { INLINES, BLOCKS } from '@contentful/rich-text-types'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { OutlineButton, theme } from '../../../../util/style'
import { Box } from '@rebass/grid'
import BlogMediaCarousel from '../BlogMediaCarousel'

const BlogCopyRow = ({ data }) => {
  const {
    image,
    video,
    copy,
    copyHtml,
    reverse = false,
    mediaCaption,
    images,
    videoCover,
  } = data

  const media = image || video || images || null

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (
          !children ||
          children.length === 0 ||
          (children.length === 1 && children[0] === '')
        ) {
          return null
        }
        return <p>{children}</p>
      },
      [INLINES.HYPERLINK]: (node, children) => {
        const value = node.content[0]?.value
        const link = node?.data?.uri

        if (value && value.match(/\[button=(.*)\]/)) {
          const buttonText = value.match(/\[button=(.*)\]/)[1]
          return (
            <Link
              to={link}
              style={{
                textDecoration: 'none',
                borderBottom: 'none',
                cursor: 'pointer',
              }}
            >
              <OutlineButton>{buttonText}</OutlineButton>
            </Link>
          )
        }

        return <Link to={link}>{children}</Link>
      },
    },
  }

  return (
    <BlogRow data={{ type: 'half', reverse }}>
      <Box>
        {images ? (
          <BlogMediaCarousel images={images} />
        ) : (
          <BlogMedia
            data={{
              width: `65.79%`,
              justifyContent: 'center',
              image: image,
              video: video,
              caption: mediaCaption?.mediaCaption,
              videoCover: videoCover,
            }}
          />
        )}
      </Box>

      <CopyWrapper media={Boolean(media)}>
        <BlogContent media={Boolean(media)}>
          {copyHtml || (copy && renderRichText(copy, options))}
        </BlogContent>
      </CopyWrapper>
    </BlogRow>
  )
}

export default BlogCopyRow

const CopyWrapper = styled(Box)`
  ${({ media }) =>
    media
      ? css`
          display: flex;
          align-items: center;
        `
      : ``};
`
